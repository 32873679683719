html,
body {
  overflow: hidden;
}

html,
body,
#root,
#layout {
  height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dynform {
  display: inline-block;
  text-align: left;
  border: 1px solid #ebedf0;
  padding: 1em !important;
  margin-right: 2em !important;
  width: 400px;
}

.dynform.roleSelect{
 width: 600px;
}

.scroll-form {
  height: 500px;
  overflow-y: scroll;
  padding: 1em;
}
.dynform h3 {
  text-align: center;
}

.roleSelect .options{
  cursor:pointer;
  color:gray;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  background-color: rgb(241, 241, 241);
}

.roleSelect .options h3{
  color:gray;
  padding: 0.5em;
}

.roleSelect .options.selected{
  background-color: rgb(247, 247, 247);
  color:black;
}

.roleSelect .options.selected h3{
  color:black;
}

.center-fragment {
  text-align: center;
}

.big-code {
  text-align: center;
  font-size: 32pt;
}

.center-fragment video {
  display: inline-block !important;
  width: 100%;
  height: 320px;
}

.price {
  color: #b12704;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.small-label {
  font-size: 0.9em;
}

p.item-total {
  display: inline-block;
  margin-top: 0.3em;
  margin-left: 1em;
}

p.item-total span {
  color: #0a7500;
  font-weight: bold;
}

.inline-item {
  display: inline-block !important;
}

.ant-layout-content {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.ant-layout-content.hide {
  display: none;
}

.zxing-wrapper{
  position: relative;
}

.zxing-wrapper .green-square{
  border: 4px solid red;
  border-style: dashed;
  position:absolute;
  width: 150px;
  height: 150px;
  top: 50%;
  margin-top: -75px;
  left: 50%;
  margin-left: -75px;
}

.zxing-wrapper .green-square .anticon{
  position:absolute;
  font-size:5em;
  width: 150px;
  height: 150px;
  top: 50%;
  margin-top: -35px;
  left: 50%;
  margin-left: -75px;
  display: none;
}

.zxing-wrapper .green-square.scanned{
  border: 4px dashed green !important;
}

.zxing-wrapper .green-square.scanned .anticon{
  display: inline-block;
}

.zxing-wrapper .blinking{
  opacity: 100%;
  -webkit-opacity: 1;
}

.zxing-wrapper .blinking.blink{
  opacity:15%;
  -webkit-opacity: 0.15;
}

#mainlogo {
  width: 130px;
}

.filter-calendar-range .ant-picker-suffix{
  display: none!important;
}

@media only screen and (max-width: 1000px) {
  .lg.hide {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .ant-page-header-heading-title {
    overflow: auto !important;
    white-space: normal !important;
  }
}
